export const environment = {
	production: true,
	environment: "production",
	shadow: false,
	region: "NA",
	brand: {
		copyright: "Zonar Systems",
		"footer-logo-filename": "zonar-footer-logo.svg",
		name: "zonar",
		"support-email": "customercare@zonarsystems.com",
		"support-phone-number": {
			"en-us": "877-843-3847",
			default: "877-843-3847",
		},
	},
	analytics: {
		google: true,
		pendo: true,
	},
	localeConfig: {
		links: {
			enabled: false,
			default: "",
		},
	},
	datadog: {
		applicationId: "cfe1252a-5130-46cc-9e04-1f65c6166cbc",
		clientToken: "pub19548a370a50698453080068542cd4ce",
		site: "us5.datadoghq.com",
	},
	pendo: {
		url: "https://cdn.pendo.io/agent/static/",
		key: "47e63a49-4477-47c2-4ea6-0f032e1785d5",
	},
	environmentConstants: {
		APP_APPLICATION_ID: "29e59f97-86d6-4f98-b5d1-0141ed450bd9",
		APP_ENDPOINT_CORE_BASE: "https://api.zonarsystems.net/core",
		APP_ENDPOINT_CORE: "https://api.zonarsystems.net/core/v1",
		APP_ENDPOINT_EVIR_BASE: "https://api.zonarsystems.net/evir",
		APP_ENDPOINT_EVIR: "https://api.zonarsystems.net/evir/v1",
		APP_ROLE_ASSIGN_MECHANIC: "48163f16-0dbd-47e5-9288-c7dcc3915f07",
		APP_ROLE_REPAIR_DEFECT_MECHANIC: "ff7114e3-7ed6-4359-b2d7-6a4b4fecb75e",
		APP_URL: "https://evir.zonarsystems.net",
		ZONAR_MAP_URL: "https://maps.production.zonarsystems.net/assets/",
	},
	auth0: {
		AUDIENCE: "https://api.zonarsystems.net",
		CLIENT_ID: "SC3AnCxwdANPMlFRE5xmrAg1o0PFrkiE",
		DOMAIN: "zonar-login.auth0.com", //"login.zonarsystems.net",
	},
	i18n: {
		supportedLanguages: ["en-US", "en-GB", "de-DE", "fr-FR", "es-ES", "it-IT"],
		defaultLanguage: "en-US",
	},
};
